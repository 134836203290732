<template>
  <div class="container">
    <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
      <div v-for="item in list" :key="item.payment_id" class="list-item" @click="onNavigator({path:'/invoice/submit?payment_id='+item.payment_id+'&payment_title='+item.payment_title})">
        <div class="list-item-title">{{ item.payment_date }}</div>
        <div class="list-item-txt">{{ item.payment_title }}</div>
        <div class="list-item-money">{{ item.payment_total }}<span style="font-size:10px;color:#969799;margin-left:4px">元</span></div>
        <div class="list-item-arrow"><van-icon name="arrow" :size="20" color="#969799" /></div>
      </div>
    </van-list>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'Invoice',
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {

  },
  methods: {
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.employee_invoice({ 'per-page': this.pageSize, page: this.pageCurrent }).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
        this.pageCurrent += 1
      })
    }
  }
}
</script>

<style lang="less" scoped>

  .container{
    padding: 10px 0;
  }
  .list{

  }

  .list-item{
    box-sizing: border-box;
    position: relative;
    width: 100%;
    background: #fff;
    padding: 10px 15px;
    margin-bottom: 10px;
    &-title{
      font-size: 16px;
      color: #666;
    }
    &-txt{
      font-size: 14px;
      color: #666;
      max-width: 220px;
    }
    &-money{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 40px;
      display: flex;
      align-items: center;
      right: 40px;
      font-size: 20px;
      font-weight: bold;
      color: #222;
    }
    &-arrow{
      position: absolute;
      top: 50%;
      height: 40px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      right: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #222;
    }
  }
</style>

